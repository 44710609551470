import "../styles/globals.scss";
import {Analytics} from "@vercel/analytics/react";
import type {AppProps} from "next/app";

import {getLogger} from "../logging";

const LOG = getLogger(__filename);

LOG.info(
  `Initializing ${process.env.PACKAGE_NAME} v${process.env.PACKAGE_VERSION} (${process.env.ENV})...`
);

export default function App({Component, pageProps}: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <Analytics />
    </>
  );
}
