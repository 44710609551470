import pino, {Logger} from "pino";

const rootLogger: Logger = pino({
  name: process.env.PACKAGE_NAME,
  level: "debug",
  transport: {
    target: "pino-pretty",
    options: {
      colorize: true,
    },
  },
});

/**
 *
 * @param modulePath
 * @param className
 */
export function getLogger(modulePath: string, className?: string): Logger {
  const match = modulePath.match(/\/(.+?)\.([tj]sx?)$/);
  if (!match) {
    return rootLogger;
  }

  const [_prefix, partialPath, _ext] = match;

  let moduleName = process.env.PACKAGE_NAME;

  if (partialPath) {
    moduleName = `${moduleName}.${partialPath.replace(/\\|\//, ".")}`;
  }

  if (className) {
    moduleName = `${moduleName}.${className}`;
  }

  return rootLogger.child({module: moduleName});
}
